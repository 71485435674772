import { useAuth } from "@clerk/nextjs";
import { Button, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useUser } from "@/auth/useUser";
import ErrorMessage, {
  FullPageError,
} from "@/components/common/errors/errorMessage";
import { LoadingAnimation } from "@/components/signin/loadingAnimation";
import useMoxieLoginLongLoading from "@/hooks/login/useMoxieLoginLongLoading";
import { getDefaultRoute } from "@/utils";

function LandingView() {
  const { isLoaded, isSignedIn, signOut } = useAuth();

  const [errorTitle, setErrorTitle] = useState("");
  const [errorDescription, setErrorDescription] = useState("");

  const router = useRouter();
  const { user, additionalUserDetails } = useUser();

  const [loginTimeout, setLoginTimeout] = useState(false);

  const { registerStartLoading, registerFinishLoading } =
    useMoxieLoginLongLoading(() => setLoginTimeout(true));

  useEffect(() => {
    // Clerk is still loading
    registerStartLoading();
    if (isLoaded) {
      if (!isSignedIn) {
        router.push("/sign-in");
      }

      if (user && !user.id) {
        // When user id does not exist, it means clerk user id
        // is not associated with any user in our database
        setErrorTitle("No account exists for this user");
        setErrorDescription("Please contact Moxie support.");
        return;
      }

      if (user && user.role === null) {
        // When user role is null, it means the default role metadata for the user in clerk is not set
        setErrorTitle(
          "There is an issue with the account configuration for this user"
        );
        setErrorDescription("Please contact Moxie support.");
        return;
      }

      if (user?.role && additionalUserDetails) {
        if (additionalUserDetails.userMedspa?.length === 0) {
          // If user does not have any usermedspas, it means
          // they do not have any role in any medspa
          setErrorTitle("This account is not connected to any medspa");
          setErrorDescription(
            "Please contact your provider support manager to get access to Moxie Suite."
          );
          return;
        }

        if (additionalUserDetails.userMedspa?.length > 0) {
          // Filter out userMedspas where the user has role of a Client
          const switchableMedspas = additionalUserDetails.userMedspa;

          if (switchableMedspas.length > 0) {
            // we don't know what medspa user wants to log into
            // pick the first index for now
            // TODO: show beter UI to allow user to select which one they want
            registerFinishLoading();
            router.push(getDefaultRoute(switchableMedspas[0]));
          }
        }
      }
    }
  }, [
    isLoaded,
    isSignedIn,
    user,
    additionalUserDetails,
    router,
    registerStartLoading,
    registerFinishLoading,
  ]);

  const handleBackToLogin = async () => {
    await signOut();
    router.push("/sign-in");
  };

  if (errorTitle)
    return (
      <FullPageError>
        <ErrorMessage
          title={errorTitle}
          description={errorDescription}
          customActionButtons={
            <Button variant="outlined" onClick={handleBackToLogin}>
              Back to Login
            </Button>
          }
        />
      </FullPageError>
    );

  return (
    <Stack
      display="flex"
      alignContent="center"
      justifyContent="center"
      width="100%"
      height="100%"
      flexWrap="wrap"
      sx={{ minHeight: "90vh" }}
    >
      <LoadingAnimation title="Redirecting you to Moxie Suite" />
      {loginTimeout && (
        <Stack gap={3}>
          <Typography color="secondary" maxWidth="400px" textAlign="center">
            Seems like this is taking longer than expected. You can report an
            issue or try with a different account.
          </Typography>
          <Stack gap={3}>
            {typeof window !== "undefined" && window?.birdeatsbug && (
              <Button
                variant="contained"
                onClick={() => window.birdeatsbug.trigger()}
              >
                Report Issue
              </Button>
            )}
            <Button variant="outlined" onClick={handleBackToLogin}>
              Back to Login
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default LandingView;
