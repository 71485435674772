import { useRef } from "react";
import useErrorLogger from "@/utils/useErrorLogger";

const MOXIE_LONG_LOGIN_TIMEOUT_SECONDS = 5;

export default function useMoxieLoginLongLoading(onLongLoading?: () => void) {
  const logError = useErrorLogger();
  const longLoadTimeout = useRef<NodeJS.Timeout | undefined>();

  const registerStartLoading = () => {
    clearTimeout(longLoadTimeout.current);

    longLoadTimeout.current = setTimeout(() => {
      logError(
        `Login time exceeded ${MOXIE_LONG_LOGIN_TIMEOUT_SECONDS} seconds`
      );
      onLongLoading?.();
    }, MOXIE_LONG_LOGIN_TIMEOUT_SECONDS * 1000);
  };

  const registerFinishLoading = () => {
    clearTimeout(longLoadTimeout.current);
  };

  return {
    registerStartLoading,
    registerFinishLoading,
  };
}
